.locked {
  pointer-events: none;
}

#newsletter-form .btn {
  height: 40px;
  -ms-border-radius: 5px;
  min-width: 95px;
  border-radius: 5px;
  line-height: 40px;
  position: relative;
  top: 0;
  right: 0;
}

/*# sourceMappingURL=index.css.map */
